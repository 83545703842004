import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="line-items"
export default class extends Controller {
  static targets = ["quantity", "presentation"];
  static values = {
    lineItemId: Number,
  };

  static targets = [
    "presentation",
    "shape",
    "cover",
    "quantity",
    "price",
    "button",
    "vinyl",
    "width",
    "height",
    "border",
  ];

  initialize() {
    this.initialWidthValue = this.widthTarget.value;
    this.initialHeightValue = this.heightTarget.value;
  }

  connect() {

  }

  lineItemTargets() {
    return [
      this.shapeTarget,
      this.coverTarget,
      this.quantityTarget,
      this.vinylTarget,
      this.widthTarget,
      this.heightTarget,
      this.borderTarget,
    ];
  }

  formData() {
    const form = this.element.querySelector("form");
    const formData = new FormData(form);

    return Object.fromEntries(formData);
  }

  selectedPresentationName(event) {
    return event.target.nextElementSibling.value;
  }

  selectedPresentationId() {
    return this.presentationTargets.filter((input) => input.checked)[0].value;
  }

  handleAmounts(event) {
    try {
      let amounts;
      if (this.selectedPresentationName(event) == "Roll") {
        amounts = [5000, 10000, 20000, 30000, 40000, 50000, 100000];
      } else if (this.selectedPresentationName(event) == "Individual") {
        amounts = [
          1, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000,
          50000, 100000,
        ];
      } else if (this.selectedPresentationName(event) == "Sheet") {
        amounts = [
          5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000,
          100000,
        ];
      }

      let innerHTML = `<option value="">Choose a quantity</option>`;

      innerHTML += amounts
        .map((amount) => {
          return `<option value="${amount}">${amount}</option>`;
        })
        .join("");

      this.quantityTarget.innerHTML = innerHTML;
      return amounts;
    } catch (error) {
      console.log(error);
    }
  }

  async edit(event) {
    await this.updatePrice(event);
  }

  async updatePrice(event) {
    const stickerId = event.target.dataset.stickerid;
    // const priceSubtotalTarget = document.getElementById("price-subtotal");
    // const priceTaxTarget = document.getElementById("price-tax");
    // const priceTotalTarget = document.getElementById("price-total");
    const isSizeValid = this.sizeValidity(
      this.formData()["sticker[width]"],
      this.formData()["sticker[height]"]
    );

    if (!isSizeValid) {
      // if size provided is not valid, reinsert valid values width/height input fields.
      this.heightTarget.value = this.initialHeightValue;
      this.widthTarget.value = this.initialWidthValue;
      return;
    }

    let csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    try {
      const response = await fetch(`/stickers/${stickerId}/pricing`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf,
        },
        body: JSON.stringify({
          sticker: this.transformPayload(this.formData()),
        }),
      });
      if (response.ok) {
        const orderAmount = await response.json();
        const lineItemPriceTarget = document.getElementById(
          `line-item-price-${this.lineItemIdValue}`
        );
        lineItemPriceTarget.textContent = orderAmount.stickerPrice;
        // this.handleBanner(orderAmount);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // enableForm() {
  //   try {
  //     // when user hits "edit selection" button, this code block will run.
  //     this.buttonTarget.textContent = "Save";

  //     for (let presentation of this.presentationTargets) {
  //       presentation.disabled = false;
  //       let label = presentation.nextElementSibling.nextElementSibling;
  //       label.classList.remove("cursor-not-allowed", "text-gray-400");
  //       label.classList.add("cursor-pointer");
  //     }

  //     for (let target of this.lineItemTargets()) {
  //       target.disabled = false;
  //       target.classList.remove("cursor-not-allowed");
  //     }
  //   } catch (error) {
  //     // TODO: handle error
  //     console.log(error);
  //   }
  // }

  handleBanner(orderAmount) {
    let orderSubtotal = orderAmount.subtotal.replace("$", "");
    if (orderSubtotal < 35) {
      document.getElementById("processing-banner").style.display = "block";
    } else {
      document.getElementById("processing-banner").style.display = "none";
    }
  }

  sizeValidity(width, height) {
    const MAX_SIZE = 60;
    const MIN_SIZE = 1;

    if (width > MAX_SIZE || width < MIN_SIZE) {
      alert(
        `Width has to be between 1 and 60 inches. You passed ${width} inches.`
      );
      return false;
    }

    if (height > MAX_SIZE || height < MIN_SIZE) {
      alert(
        `Width has to be between 1 and 60 inches. You passed ${height} inches`
      );
      return false;
    }

    return true;
  }

  currentPresentation() {
    return this.presentationTargets.find((presentation) => {
      return presentation.checked;
    });
  }

  transformPayload(payload) {
    const transformedPayload = {
      height: payload["sticker[height]"],
      width: payload["sticker[width]"],
      sticker_vinyls_attributes: [
        {
          vinyl_id: payload["sticker[sticker_vinyls_attributes][0][vinyl_id]"],
        },
      ],
      sticker_presentations_attributes: [
        {
          presentation_id:
            payload[
              "sticker[sticker_presentations_attributes][0][presentation_id]"
            ],
        },
      ],
      sticker_covers_attributes: [
        {
          cover_id: payload["sticker[sticker_covers_attributes][0][cover_id]"],
        },
      ],
      sticker_borders_attributes: [
        {
          border_id:
            payload["sticker[sticker_borders_attributes][0][border_id]"],
        },
      ],
      sticker_shapes_attributes: [
        {
          shape_id: payload["sticker[sticker_shapes_attributes][0][shape_id]"],
        },
      ],
      line_items_attributes: [
        {
          id: payload["sticker[line_items_attributes][0][id]"],
          quantity: payload["sticker[line_items_attributes][0][quantity]"],
          price: payload["sticker[line_items_attributes][0][price]"] || 1,
          itemable_type: "Cart",
          itemable_id:
            payload["sticker[line_items_attributes][0][itemable_id]"],
        },
      ],
    };

    if (transformedPayload.sticker_covers_attributes[0].cover_id === "") {
      delete transformedPayload.sticker_covers_attributes;
    }
    if (transformedPayload.sticker_borders_attributes[0].border_id === "") {
      delete transformedPayload.sticker_borders_attributes;
    }
    return transformedPayload;
  }
}
