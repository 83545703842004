import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cart"
export default class extends Controller {
  static values = {
    guestAccount: Boolean,
    userEmail: String,
  };

  async connect() {
    if (this.saveConditions()) {
      this.saveUserToEmailLocalStorage();
    }

    if (this.retrieveConditions()) {
      await this.synchronizeCarts();
    }
  }

  async synchronizeCarts() {
    const stored = JSON.parse(localStorage.getItem(this.storageKey()));
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    try {
      const response = await fetch("/transfer_guest_account", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf,
        },
        body: JSON.stringify({
          email: stored.email,
        }),
      });
      const data = await response.json();
      if (data.success) {
        localStorage.removeItem(this.storageKey());
      }
    } catch (error) {
      console.log(error);
    }
  }

  saveUserToEmailLocalStorage() {
    localStorage.setItem(
      this.storageKey(),
      JSON.stringify({
        email: this.userEmailValue,
      })
    );
  }

  saveConditions() {
    return this.guestAccountValue;
  }

  retrieveConditions() {
    return localStorage.getItem(this.storageKey()) && !this.guestAccountValue;
  }

  storageKey() {
    return "mia-stickers-cart";
  }
}
