import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shape"
export default class extends Controller {
  connect() {
    // Style presentation radio buttons
    const circle = document.getElementById('shape-0');
    const square = document.getElementById('shape-1');
    // TODO: Die Cut
    // const dieCut = document.getElementById('presentation-2');

    const circleLabel = circle.getElementsByTagName('label')[0];
    const squareLabel = square.getElementsByTagName('label')[0];
    // const dieCutLabel = dieCut.getElementsByTagName('label')[0];

    circleLabel.style.height = `${circle.clientWidth}px`;
    circleLabel.style.maxHeight = `${circle.clientWidth}px`;
    circleLabel.style.borderRadius = `1000px`;
    squareLabel.style.height = `${square.clientWidth}px`;
    squareLabel.style.maxHeight = `${square.clientWidth}px`;
    // dieCutLabel.style.border = 'none';
    // dieCutLabel.style.height = `${dieCut.clientWidth}px`;

    window.addEventListener('resize', () => {
      circleLabel.style.height = `${circle.clientWidth}px`;
      squareLabel.style.height = `${square.clientWidth}px`;
      // dieCutLabel.style.height = `${dieCut.clientWidth}px`;
    });
  }
}
