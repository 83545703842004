import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

// Connects to data-controller="custom-pricing"
export default class extends Controller {
  static targets = ["price", "presentation", "quantity"];

  connect() {
    this.change = debounce(this.change.bind(this), 300);
  }

  selectedPresentationName() {
    return Array.from(
      this.presentationTarget.querySelectorAll(
        // input type radio
        "input[type='radio']"
      )
    ).filter((input) => input.checked)[0].nextElementSibling.value;
  }

  handleAmounts() {
    let amounts;
    if (this.selectedPresentationName() == "Roll") {
      amounts = [5000, 10000, 20000, 30000, 40000, 50000, 100000];
    } else if (this.selectedPresentationName() == "Individual") {
      amounts = [
        1, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000,
        100000,
      ];
    } else if (this.selectedPresentationName() == "Sheet") {
      amounts = [
        5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000,
        100000,
      ];
    }

    let innerHTML = `<option value="">Choose a quantity</option>`;

    innerHTML += amounts
      .map((amount) => {
        return `<option value="${amount}">${amount}</option>`;
      })
      .join("");

    this.quantityTarget.querySelector("select").innerHTML = innerHTML;
    return amounts;
  }

  presentationId() {
    return parseInt(
      this.transformPayload(this.payload())[
        "sticker_presentations_attributes"
      ][0]["presentation_id"]
    );
  }

  async change(event) {
    if (event.target.type === "radio") {
      this.handleAmounts();
    }
    if (this.fetchConditions(this.payload())) {
      this.priceTarget.classList.add("transition", "ease-out", "opacity-0");
      try {
        const response = await fetch("/stickers/quote/pricing", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": this.csrf(),
          },
          body: JSON.stringify({
            sticker: this.transformPayload(this.payload()),
          }),
        });
        const data = await response.json();
        this.priceTarget.textContent = data.stickerPrice;
        this.removeOpacity();
      } catch (error) {
        this.removeOpacity();
      }
    }
  }

  payload() {
    const form = this.element;
    const formData = new FormData(form);
    return Object.fromEntries(formData);
  }

  transformPayload(payload) {
    const transformedPayload = {
      height: payload["sticker[height]"],
      width: payload["sticker[width]"],
      sticker_vinyls_attributes: [
        {
          vinyl_id: payload["sticker[sticker_vinyls_attributes][0][vinyl_id]"],
        },
      ],
      sticker_presentations_attributes: [
        {
          presentation_id:
            payload[
              "sticker[sticker_presentations_attributes][0][presentation_id]"
            ],
        },
      ],
      sticker_covers_attributes: [
        {
          cover_id: payload["sticker[sticker_covers_attributes][0][cover_id]"],
        },
      ],
      sticker_borders_attributes: [
        {
          border_id:
            payload["sticker[sticker_borders_attributes][0][border_id]"],
        },
      ],
      sticker_shapes_attributes: [
        {
          shape_id: payload["sticker[sticker_shapes_attributes][0][shape_id]"],
        },
      ],
      line_items_attributes: [
        {
          id: payload["sticker[line_items_attributes][0][id]"],
          quantity: payload["sticker[line_items_attributes][0][quantity]"],
          price: payload["sticker[line_items_attributes][0][price]"] || 1,
          itemable_type: "Cart",
          itemable_id:
            payload["sticker[line_items_attributes][0][itemable_id]"],
        },
      ],
    };

    if (transformedPayload.sticker_covers_attributes[0].cover_id === "") {
      delete transformedPayload.sticker_covers_attributes;
    }
    if (transformedPayload.sticker_borders_attributes[0].border_id === "") {
      delete transformedPayload.sticker_borders_attributes;
    }
    return transformedPayload;
  }

  removeOpacity() {
    this.priceTarget.classList.remove("opacity-0");
  }

  fetchConditions(payload) {
    return (
      payload["sticker[height]"] &&
      payload["sticker[width]"] &&
      payload["sticker[line_items_attributes][0][quantity]"] &&
      payload["sticker[sticker_vinyls_attributes][0][vinyl_id]"] &&
      payload["sticker[sticker_presentations_attributes][0][presentation_id]"]
    );
  }

  csrf() {
    return document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
  }
}
