import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="design-job"
export default class extends Controller {
  connect() {
    this.clearFileInput();
  }

  clearFileInput() {
    let form = this.element;
    let fileInput = form.querySelector('input[type="file"]');
    if (fileInput.files.length > 0) {
      fileInput.value = null;
    }
  }
}
