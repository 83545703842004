import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  previewFile(e) {
    const preview = document.querySelector("#image-preview");
    const file = document.querySelector("input[type=file]").files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        preview.src = reader.result;
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
      preview.classList.add('neo-border');
      preview.classList.add('custom-shadow');
    }
  }
}
