import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["line", "city", "state", "zip", "checkout"];

  connect() {
    const form = document.querySelector(".billing-form");
    if (form) {
      this.validate();
    }
  }

  validate() {
    const formTargets = [this.lineTarget, this.cityTarget, this.stateTarget, this.zipTarget];

    if (formTargets.every(field => field.value.length > 0)) {
      this.checkoutTarget.disabled = false;
    } else {
      this.checkoutTarget.disabled = true;
    }
  }
}
