import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="revisions"
export default class extends Controller {
  static targets = ["status", "form", "response"];

  approve(event) {
    event.preventDefault();
    this.statusTarget.value = "approved";
    this.formTarget.submit();
  }

  reject(event) {
    event.preventDefault();
    this.statusTarget.value = "rejected";
    this.responseTarget.classList.remove("hidden");
  }
}
